// @import "../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../node_modules/foundation-sites/scss/foundation.scss";

// Global styles
@include foundation-global-styles;
// @include foundation-forms;
@include foundation-typography;

// Grids (choose one)
// @include foundation-xy-grid-classes;
@include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
//@include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
//@include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
// @include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

//Swatches
$primary: #f1c101;
$grey-200: #f2f4f7;
$grey-300: #d0d5dd;
$grey-500: #475467;

.column-block {
  margin-bottom: 0;
  background: pink;
}

.nav {
  width: 100%;
  nav {
    ul {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding: 1.5em 0;

      li {
        margin: 0 0 0 1.5em;
        list-style: none;

        a {
          font-weight: bold;
          text-transform: uppercase;
          color: $black;
          text-decoration: none;

          &.active,
          &:hover{
              color: $primary;
          }
        }
      }
    }
  }
}

a{
  font-weight: bold;
  &.follow:after{
    content:' \2192';  }
}

footer {
  font-size: 0.75rem;
  margin: 1em 0;
  padding: 4em 0 0;
  text-align: center;
}

.section--home-hero {
  display: flex;
  flex-direction: column;

  .home--hero-content {
    display: flex;
    min-height: 560px;
    align-items: center;
    flex-direction: column;

    h1 {
      font-size: 2.25rem;
      // margin-bottom: 3em;
      margin-top: 3em;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}

.section--project-hero {
  display: flex;
  flex-direction: column;

  .project--hero-content {
    .project--intro {
      margin-top: 7em;
      width: 90%;

      @include breakpoint(small down) {
        margin-top: 3em;
        width: 100%;
      }

      p {
        font-weight: 700;
      }
    }
  }

  &.ux {
    background: rgb(97, 114, 243);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(97, 114, 243, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(97, 114, 243, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(97, 114, 243, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6172F3", endColorstr="#FFFFFF", GradientType=1);

    .project--hero-content {
      min-height: 618px;

      .project--hero-image {
        animation: fadeInDown 0.5s;
        @include breakpoint(medium down) {
          margin-top: -5em;
        }
      }
    }
  }

  &.ui {
    background: rgb(82,70,170);
    background: -moz-linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 52%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 52%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 52%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5246aa",endColorstr="#ffffff",GradientType=1);
    .project--hero-content {
      min-height: 618px;

      .project--hero-image {
        animation: fadeInDown 0.5s;
        @include breakpoint(medium down) {
          margin-top: -5em;
        }
      }
    }
  }
}

.section--project-list {
  .project-list {
    .project {
      border-radius: 5px;
      padding: 0 78px;
      min-height: 400px;
      margin-bottom: 48px;
      position: relative;

      @include breakpoint(small down) {
        padding: 24px;
      }

      // display: flex;
      // align-items: center;

      &.case-study {
        background-color: #6172f3;
      }

      &.case-study-ui {
        // background-color: #5246aa ;
        background: rgb(82,70,170);
    background: -moz-linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 100%);
    background: linear-gradient(180deg, rgba(82,70,170,1) 0%, rgba(250,135,157,1) 100%);
      }

      &.web {
        background-color: #fe7e08;
      }

      &.design {
        background-color: #7ac142;
      }

      div.bg {
        width: 100%;
        height: 100%;
        padding: 0 78px;
        display: flex;
        align-items: center;

        @include breakpoint(small down) {
          padding: 0;
        }

        @include breakpoint(medium up) {
          background: url(./assets/images/project-bg.svg) right no-repeat; 
          top: 0;
          left: 0;
          position: absolute;
        }

        > div {
          width: 100%;
        }

        .project-title {
          font-size: 2.25rem;
          font-weight: $header-font-weight;
          margin-bottom: 32px;
          width: 90%;

          @include breakpoint(small down) {
            width: 100%;
          }
        }

        .project-list--content {
          display: flex;
          align-items: center;

          @include breakpoint(small down) {
            flex-direction: column;
          }

          & > div {
            width: 50%;

            @include breakpoint(small down) {
              width: 100%;
            }
          }

          .project-image {
            display: flex;
            justify-content: right;
            animation: fadeInRight 0.5s;

            @include breakpoint(medium up) {
              img { 
                max-height: 300px;
              }
            }
          }

          @include breakpoint(small down) {
            .button.grey-500{
              color: $white;
              border-color: $white;
            }
          }
         
        }
      }
    }
  }
}

.section--about-hero{
    margin-top: 2em;
 
    img.usericon{
      max-width: 250px;
      margin: 0 auto 2em;
      display: block;
    }

    .buttons{
      margin-top: 2em;
      text-align: center;
    }
}

.project--content-chapter {
  padding: 1em 0;
  margin: 4em 0;

  &.first {
    margin-top: 0;
  }

  &.alt {
    background: $grey-200;
    padding: 3em 0;
  }

  p + h3 {
    margin-top: 2em;
  }

  figure{
  
  &.project--image {
    margin: 0;
    padding: 3em 0 0 0;
    border-radius: $global-radius;
    @include breakpoint(small down) {
      margin-bottom: 1.5em;
    }
    img {
      margin: 0 auto;
    }

   
  }
  img.shadow{
    box-shadow: 0px 0px 8px rgba(82, 70, 170, 0.15);
    border-radius: 18px;
  }

  img.threerow{
    &.centered{
      margin: 0 1.5em;
    }

    @include breakpoint(small down) { 
      margin: 1.5em 0;
      &.centered{
        margin: 1.5em 0;
      }
    }

   
  }

 
}
}

.callout {
  background: $grey-300;
  padding: 1.5em;
  border-radius: 5px;
  margin: 3em 0 0;

  + h3 {
    margin-top: 2em;
  }

  ul,
  p {
    margin-bottom: 0;
  }
}

.button {
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: $button-font-weight;
  text-decoration: none;
  @include breakpoint(small down) { 
      display: block;
  }


  &.outline {
    border: 3px solid $button-color;
    background-color: transparent;
  }

  &:hover {
    color: $black;
    border-color: $black;
  }

  +.button{
      margin-left: 2em;
      @include breakpoint(small down) { 
       margin-left: 0;
    }
  }
} 




figure {
  margin: 2.25em;
  text-align: center;

  &.moodboard{
    margin: 0 0 1.5em 0;
  }

  figcaption {
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 1.5em;
  }
}

.text-centered {
  text-align: center;
}

@include breakpoint(small down) {
  .reorder {
    .row {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

//Background colours
.burgundy {
  background: rgb(68, 0, 34);
  background: -moz-linear-gradient(
    213deg,
    rgba(68, 0, 34, 1) 0%,
    rgba(102, 0, 51, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(68, 0, 34, 1) 0%,
    rgba(102, 0, 51, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(68, 0, 34, 1) 0%,
    rgba(102, 0, 51, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#440022", endColorstr="#660033", GradientType=1);
}

.orange {
  background: rgb(255, 122, 9);
  background: -moz-linear-gradient(
    213deg,
    rgba(255, 122, 9, 1) 0%,
    rgba(244, 162, 9, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(255, 122, 9, 1) 0%,
    rgba(244, 162, 9, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(255, 122, 9, 1) 0%,
    rgba(244, 162, 9, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7a09", endColorstr="#f4a209", GradientType=1);
}

.pink {
  background: rgb(238, 55, 119);
  background: -moz-linear-gradient(
    213deg,
    rgba(238, 55, 119, 1) 0%,
    rgba(89, 94, 170, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(238, 55, 119, 1) 0%,
    rgba(89, 94, 170, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(238, 55, 119, 1) 0%,
    rgba(89, 94, 170, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ee3777", endColorstr="#595eaa", GradientType=1);
}

.gold {
  background: rgb(124, 101, 63);
  background: -moz-linear-gradient(
    213deg,
    rgba(124, 101, 63, 1) 0%,
    rgba(200, 164, 105, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(124, 101, 63, 1) 0%,
    rgba(200, 164, 105, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(124, 101, 63, 1) 0%,
    rgba(200, 164, 105, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7c653f", endColorstr="#c8a469", GradientType=1);
}

.bluegreen {
  background: rgb(17, 52, 72);
  background: -moz-linear-gradient(
    213deg,
    rgba(17, 52, 72, 1) 0%,
    rgba(20, 191, 152, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(17, 52, 72, 1) 0%,
    rgba(20, 191, 152, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(17, 52, 72, 1) 0%,
    rgba(20, 191, 152, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#113448", endColorstr="#14bf98", GradientType=1);
}

.blue {
  background: rgb(0, 101, 179);
  background: -moz-linear-gradient(
    213deg,
    rgba(0, 101, 179, 1) 0%,
    rgba(70, 197, 242, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(0, 101, 179, 1) 0%,
    rgba(70, 197, 242, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(0, 101, 179, 1) 0%,
    rgba(70, 197, 242, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0065b3", endColorstr="#46c5f2", GradientType=1);
}

.grey {
  background: rgb(12, 16, 20);
  background: -moz-linear-gradient(
    213deg,
    rgba(12, 16, 20, 1) 0%,
    rgba(27, 35, 42, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(12, 16, 20, 1) 0%,
    rgba(27, 35, 42, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(12, 16, 20, 1) 0%,
    rgba(27, 35, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c1014", endColorstr="#1b232a", GradientType=1);
}

.lightgrey {
  background: rgb(124, 124, 124);
  background: -moz-linear-gradient(
    213deg,
    rgba(124, 124, 124, 1) 0%,
    rgba(213, 213, 213, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(124, 124, 124, 1) 0%,
    rgba(213, 213, 213, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(124, 124, 124, 1) 0%,
    rgba(213, 213, 213, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7c7c7c", endColorstr="#d5d5d5", GradientType=1);
}

.purple {
  background: rgb(90, 104, 176);
  background: -moz-linear-gradient(
    213deg,
    rgba(90, 104, 176, 1) 0%,
    rgba(244, 149, 190, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(90, 104, 176, 1) 0%,
    rgba(244, 149, 190, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(90, 104, 176, 1) 0%,
    rgba(244, 149, 190, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5a68b0",endColorstr="#f495be",GradientType=1);
}

.green {
  background: rgb(75, 134, 29);
  background: -moz-linear-gradient(
    213deg,
    rgba(75, 134, 29, 1) 0%,
    rgba(122, 193, 66, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(75, 134, 29, 1) 0%,
    rgba(122, 193, 66, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(75, 134, 29, 1) 0%,
    rgba(122, 193, 66, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b861d",endColorstr="#7ac142",GradientType=1);
}

.red {
  background: rgb(213, 49, 52);
  background: -moz-radial-gradient(
    circle,
    rgba(213, 49, 52, 1) 0%,
    rgba(130, 24, 19, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(213, 49, 52, 1) 0%,
    rgba(130, 24, 19, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(213, 49, 52, 1) 0%,
    rgba(130, 24, 19, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d53134",endColorstr="#821813",GradientType=1);
}

.tealgreen {
  background: rgb(183, 215, 160);
  background: -moz-linear-gradient(
    213deg,
    rgba(183, 215, 160, 1) 0%,
    rgba(20, 170, 187, 1) 100%
  );
  background: -webkit-linear-gradient(
    213deg,
    rgba(183, 215, 160, 1) 0%,
    rgba(20, 170, 187, 1) 100%
  );
  background: linear-gradient(
    213deg,
    rgba(183, 215, 160, 1) 0%,
    rgba(20, 170, 187, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b7d7a0",endColorstr="#14aabb",GradientType=1);
}

//Animations
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.reveal:focus {
  outline: none;
}

.reveal {
  .infobox {
    display: flex;
    flex-direction: row;

    @include breakpoint(medium down) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      padding: 2em;
    }

    &__profile {
      background: $primary;
      display: flex;

      image {
        width: 50%;
      }
    }
  }
}




// .notfound-container{
//   position: absolute;
//   display: flex;
//   width: 100vw;
//   height: 100vh;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   flex-direction: column;
//   text-transform: uppercase;
//   letter-spacing: 10px;
//   font-weight: 900;
//   text-align: center;

//   h1{
//     font-size: 9.4em; 
//     @include breakpoint(small down) {
      
//           font-size: 5em;
      
//     }  
//     span{
//       color: #f1c101;
//     } 
//   }
 
//   .cow{
//     width: 300px;
//     transform: translatey(0px);
//     animation: float 6s ease-in-out infinite;
//   }
  
// }

// @keyframes float {
// 0% {
  
//   transform: translatey(0px);
// }
// 50% {
  
//   transform: translatey(-20px);
// }
// 100% {
  
//   transform: translatey(0px);
// }
// }
